<template scoped>
  <Panel-CRUD
    ref="crud"
    :guid="guid"
    :endpoint="endpoint"
    :toPage="toPage"
    :resourceServer="resourceServer"
    :resourceId="resourceId"
    title="Group"
    :create="create"
    :resourceServerApi="false"
    apiPath="Backend/Group"
  >
    <template>
      <el-table-column prop="name" label="Name" width="360"> </el-table-column>
      <el-table-column prop="enable" label="Live Mode">
        <template slot-scope="scope">
          <el-switch
            @change="setEnable(scope.row)"
            v-model="scope.row.enable"
            active-color="#13ce66"
            inactive-color="#ff4949"
          >
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="action" label="Action">
        <template>
          <el-row>
            <el-col :span="24">
              <el-tooltip
                class="item"
                effect="dark"
                content="Edit"
                placement="top"
              >
                <el-button>Edit</el-button>
              </el-tooltip>
            </el-col>
          </el-row>
        </template>
      </el-table-column>
    </template>
  </Panel-CRUD>
</template>

<script>
export default {
  props: {
    guid: String,
    endpoint: String,
    toPage: Function,
    resourceServer: String,
    resourceId: String,
  },
  components: {
   "Panel-CRUD": () => import("cms_infrastructure_web/src/sdk/Admin/CRUD/CRUD-List.vue"),
  },
  methods: {
    setEnable: function (item) {
      this.$refs.crud.setEnable(item);
    },
    create: function () {
      this.toPage(
        "applications/user/j1/business/notification-hub/User/NotificationHub-Group-Create"
      );
    },
  },
};
</script>